// resources/js/components/HelloReact.js

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Document, Page, View, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import moment from "moment";

import { StyleSheet } from "@react-pdf/renderer";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.js",
    import.meta.url
).toString();

const styles = StyleSheet.create({
    page: { backgroundColor: "tomato" },
    section: { color: "white", textAlign: "center", margin: 30 },
});

export default function HelloReact() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useEffect(() => {
        //console.log(window.location.pathname);
        let path = window.location.pathname;
        let arrString = path.split("/");
        //console.log(arrString);
        let token = arrString[2];
        //console.log(token);
        let loadingMain = document.getElementById("loadingMain");
        loadingMain.style.display = "none";
        let base_url = window.location.origin;
        axios
            .get(base_url + "/api/public-sertif/" + token)
            .then((res) => {
                //console.log(res);
                setData(res.data);
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return (
            <div className="container text-center">
                <div
                    className="loadingio-spinner-spinner-ct2n488g7ke"
                    style={{ backgroundColor: "white" }}
                >
                    <div className="ldio-nzclrcb31u9">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div
            className="outer-container"
            style={{ backgroundColor: "#F5F8FF", minHeight: "80%" }}
        >
            <div className="container container-upper-certif">
                <div className="row" id="rowUpper">
                    <div
                        className="col-xs-12 col-md-8 text-center"
                        id="colCertif"
                    >
                        <Document
                            file={data?.url_sertificate}
                            onLoadSuccess={onDocumentLoadSuccess}
                            renderTextLayer={false}
                            className="container-document"
                        >
                            <Page style={styles.page} pageNumber={pageNumber} />
                        </Document>
                    </div>
                    <div className="col-xs-12 col-md-4" id="colDetail">
                        <div className="container-detailreact">
                            <h3
                                className="new_headline_6"
                                style={{
                                    color: "#1B8497",
                                    textAlign: "center",
                                }}
                            >
                                {data.group_name}
                                <br></br>Certificate
                            </h3>

                            <img
                                className="pictureCertif"
                                src={
                                    data.user_photo
                                        ? data.user_photo
                                        : "https://picsum.photos/88/88"
                                }
                                alt="bootcamp-image"
                            />
                            <h3
                                className="new_headline_6"
                                style={{ color: "black", textAlign: "center" }}
                            >
                                {data.user_name}
                            </h3>
                            <h4
                                className="new_body1_regular"
                                style={{ color: "black", textAlign: "center" }}
                            >
                                Diberikan Pada <br></br>{" "}
                                <span
                                    className="new_headline_6"
                                    style={{ color: "black" }}
                                >
                                    {moment(
                                        data.certif_date,
                                        "YYYY-MM-DD"
                                    ).format("DD MMM YYYY")}
                                </span>
                            </h4>

                            <h4
                                className="new_body1_regular"
                                style={{ color: "black", textAlign: "center" }}
                            >
                                Predikat <br></br>
                                <span
                                    className="new_headline_6"
                                    style={{ color: "black" }}
                                >
                                    {data.predikat}
                                </span>
                            </h4>

                            <a
                                href={data.url_sertificate}
                                target="__blank"
                                role="button"
                                type="button"
                                className="btn btn-primary btn-lg active"
                                style={{
                                    backgroundColor: "#3DC3DD",
                                    borderColor: "#3DC3DD",
                                }}
                            >
                                <i
                                    className="fa fa-download"
                                    style={{ marginRight: "8px" }}
                                ></i>
                                Download
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

if (document.getElementById("hello-react")) {
    ReactDOM.render(<HelloReact />, document.getElementById("hello-react"));
}
